import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { TableAreaSetInput } from "types/graphql";

import { TableArea } from "../types";

export type EditTableAreaFormValues = Pick<TableArea, "name" | "showOnlyActive">;

const getInitialValues = (tableArea: TableArea) => {
  const { name, showOnlyActive } = tableArea;
  return { name, showOnlyActive };
};

export const EditTableAreaFormItem = createFormItem<EditTableAreaFormValues>();

export const useEditTableAreaForm = (
  tableArea: TableArea,
  onSubmit: (tableArea: TableAreaSetInput) => Promise<void>,
) => {
  const [form] = Form.useForm<EditTableAreaFormValues>();
  const initialValues = getInitialValues(tableArea);

  const submit = useCallback(async () => {
    const { name, showOnlyActive } = form.getFieldsValue();

    await onSubmit({ name, showOnlyActive });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
