import React, { memo } from "react";
import { Checkbox } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditTableAreaFormItem } from "../useEditTableAreaForm";

type Props = Omit<FormItemProps, "children">;

export const ShowOnlyActiveField = memo<Props>((props) => (
  <EditTableAreaFormItem valuePropName="checked" {...props} name="showOnlyActive">
    <Checkbox>利用中の卓のみ表示する</Checkbox>
  </EditTableAreaFormItem>
));
