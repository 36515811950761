import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditTableAreaGetTableArea = gql`
    query EditTableAreaGetTableArea($id: uuid!) {
  tableArea(where: {id: {_eq: $id}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    name
    showOnlyActive
  }
}
    `;
export const EditTableAreaUpdateTableArea = gql`
    mutation EditTableAreaUpdateTableArea($id: uuid!, $tableArea: tableArea_set_input!) {
  update_tableArea(_set: $tableArea, where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type EditTableAreaGetTableAreaQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type EditTableAreaGetTableAreaQuery = (
  { __typename?: 'query_root' }
  & { tableArea: Array<(
    { __typename?: 'tableArea' }
    & Pick<Types.TableArea, 'id' | 'name' | 'showOnlyActive'>
  )> }
);

export type EditTableAreaUpdateTableAreaMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  tableArea: Types.TableAreaSetInput;
}>;


export type EditTableAreaUpdateTableAreaMutation = (
  { __typename?: 'mutation_root' }
  & { update_tableArea?: Types.Maybe<(
    { __typename?: 'tableArea_mutation_response' }
    & Pick<Types.TableAreaMutationResponse, 'affected_rows'>
  )> }
);


export const EditTableAreaGetTableAreaDocument = gql`
    query EditTableAreaGetTableArea($id: uuid!) {
  tableArea(where: {id: {_eq: $id}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    name
    showOnlyActive
  }
}
    `;

/**
 * __useEditTableAreaGetTableAreaQuery__
 *
 * To run a query within a React component, call `useEditTableAreaGetTableAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditTableAreaGetTableAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditTableAreaGetTableAreaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditTableAreaGetTableAreaQuery(baseOptions: Apollo.QueryHookOptions<EditTableAreaGetTableAreaQuery, EditTableAreaGetTableAreaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditTableAreaGetTableAreaQuery, EditTableAreaGetTableAreaQueryVariables>(EditTableAreaGetTableAreaDocument, options);
      }
export function useEditTableAreaGetTableAreaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditTableAreaGetTableAreaQuery, EditTableAreaGetTableAreaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditTableAreaGetTableAreaQuery, EditTableAreaGetTableAreaQueryVariables>(EditTableAreaGetTableAreaDocument, options);
        }
export type EditTableAreaGetTableAreaQueryHookResult = ReturnType<typeof useEditTableAreaGetTableAreaQuery>;
export type EditTableAreaGetTableAreaLazyQueryHookResult = ReturnType<typeof useEditTableAreaGetTableAreaLazyQuery>;
export type EditTableAreaGetTableAreaQueryResult = Apollo.QueryResult<EditTableAreaGetTableAreaQuery, EditTableAreaGetTableAreaQueryVariables>;
export const EditTableAreaUpdateTableAreaDocument = gql`
    mutation EditTableAreaUpdateTableArea($id: uuid!, $tableArea: tableArea_set_input!) {
  update_tableArea(_set: $tableArea, where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type EditTableAreaUpdateTableAreaMutationFn = Apollo.MutationFunction<EditTableAreaUpdateTableAreaMutation, EditTableAreaUpdateTableAreaMutationVariables>;

/**
 * __useEditTableAreaUpdateTableAreaMutation__
 *
 * To run a mutation, you first call `useEditTableAreaUpdateTableAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTableAreaUpdateTableAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTableAreaUpdateTableAreaMutation, { data, loading, error }] = useEditTableAreaUpdateTableAreaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tableArea: // value for 'tableArea'
 *   },
 * });
 */
export function useEditTableAreaUpdateTableAreaMutation(baseOptions?: Apollo.MutationHookOptions<EditTableAreaUpdateTableAreaMutation, EditTableAreaUpdateTableAreaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTableAreaUpdateTableAreaMutation, EditTableAreaUpdateTableAreaMutationVariables>(EditTableAreaUpdateTableAreaDocument, options);
      }
export type EditTableAreaUpdateTableAreaMutationHookResult = ReturnType<typeof useEditTableAreaUpdateTableAreaMutation>;
export type EditTableAreaUpdateTableAreaMutationResult = Apollo.MutationResult<EditTableAreaUpdateTableAreaMutation>;
export type EditTableAreaUpdateTableAreaMutationOptions = Apollo.BaseMutationOptions<EditTableAreaUpdateTableAreaMutation, EditTableAreaUpdateTableAreaMutationVariables>;